import {
  Avatar,
  Box,
  Container,
  Grid,
  Slide,
  Stack,
  Typography,
} from "@mui/material"
import SectionTitle from "../Title/SectionTitle"
import React from "react"
import { useInView } from "react-intersection-observer"
import { TestimonyData } from "../../core/constants/TestimonyConsts"

const HomeTestimony = () => {
  const [animation, setAnimation] = React.useState(false)

  const {
    ref: refView,
    inView,
    entry,
  } = useInView({
    threshold: 0.5,
    delay: 100,
    onChange: (inView, entry) => {
      if (inView && !animation) {
        setAnimation(true)
      }
    },
  })

  return (
    <div ref={refView}>
      <Box
        sx={{
          backgroundImage: "linear-gradient(to right, #FAFAFA, #ECEFF1)",
          width: "100%",
          minHeight: "500px",
        }}
      >
        <Container sx={{ px: { xs: 2, sm: 5 }, py: 5 }}>
          <SectionTitle title="Depoimentos" subtitle="" />
          <Slide direction="up" in={animation} mountOnEnter unmountOnExit>
            <Grid container spacing={4} sx={{ mt: 2 }}>
              {TestimonyData.map((card) => (
                <Grid item key={`serv${card.id}`} xs={12} sm={6} /* md={4} */>
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      alt={card.title}
                      src={card.image}
                      sx={{ width: 72, height: 72 }}
                    />
                    <Box>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        sx={{ width: "100%", mt: 1 }}
                      >
                        {card.title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {card.description}
                      </Typography>
                      <Typography variant="body2" fontWeight={500}>
                        {card.testimony}
                      </Typography>
                    </Box>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Slide>
        </Container>
      </Box>
    </div>
  )
}

export default HomeTestimony
