export const TestimonyData = [
  {
    id: 5,
    title: "Enzo Oliveira Silveira",
    description: "Diretor Corpu's Talk",
    image: `${process.env.PUBLIC_URL}/assets/testimony/enzo.jpg`,
    testimony: `Quero aproveitar pra agradecer ao Alan, nosso programador do Corpus Talk. Cara, seu trabalho tem sido simplesmente incrível! A dedicação e o empenho que você coloca no desenvolvimento do app fazem toda a diferença.\n\nÉ incrível ver como você encara os desafios com tanta criatividade e foco, sempre buscando as melhores soluções. O Corpus Talk está ganhando forma graças ao seu talento e ao esforço que você coloca em cada detalhe. Valeu demais por fazer parte dessa caminhada com a gente.`,
  },
  {
    id: 4,
    title: "Décio Morais",
    description: "Gestor TI Astec",
    image: `${process.env.PUBLIC_URL}/assets/testimony/decio.jpg`,
    testimony:
      "Gostaríamos de expressar nossa profunda gratidão à DevAll Tech pela excepcional expertise em integração de serviços e desenvolvimento web. Seu profissionalismo, comprometimento e entrega de soluções inovadoras foram fundamentais para o sucesso de nossos projetos. Agradecemos sinceramente pela parceria valiosa e pela qualidade exemplar dos serviços prestados.",
  },
  {
    id: 3,
    title: "Rodrigo Holanda",
    description: "Diretor Kanekria",
    image: `${process.env.PUBLIC_URL}/assets/testimony/rodrigo.jpg`,
    testimony: "Soluções práticas e acessíveis!",
  },
  {
    id: 2,
    title: "Marcelo Sanches",
    description: "Diretor Clique Comida",
    image: `${process.env.PUBLIC_URL}/assets/testimony/marcelo.jpg`,
    testimony: "Pensando sempre na satisfação do cliente final.",
  },
  {
    id: 1,
    title: "Sérgio Hoffmann",
    description: "Gerente de TI Donana",
    image: `${process.env.PUBLIC_URL}/assets/testimony/sergio.jpg`,
    testimony:
      "Nós da empresa Donana Alimentos estamos muito satisfeitos com o trabalho da empresa DevAll Tech. Sempre atenciosos e pacientes as nossas dúvidas e com muita agilidade e boa vontade na execução de tudo o que a eles foram solicitados. Recomendo, com certeza, a todas as empresas ou pessoas que buscam um trabalho sério e com prazo de entrega.",
  },
]
