import * as React from "react"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import HomeIdea from "../../components/Home/HomeIdea"
import HomeDrawer from "../../components/Home/HomeDrawer"
import HomeTop from "../../components/Home/HomeTop"
import HomeAbount from "../../components/Home/HomeAbount"
import HomeMethodology from "../../components/Home/HomeMethodology"
import HomeService from "../../components/Home/HomeService"
import HomePortfolio from "../../components/Home/HomePortfolio"
import HomeTeam from "../../components/Home/HomeTeam"
import HomeTestimony from "../../components/Home/HomeTestimony"
import HomeClient from "../../components/Home/HomeClient"
import HomeContact from "../../components/Home/HomeContact"
import HomeFoother from "../../components/Home/HomeFoother"
import { Fab } from "@mui/material"
import { green } from "@mui/material/colors"

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
}

const drawerWidth = 240
const navItems = ["Home", "Sobre", "Serviços", "Portfólio", "Equipe", "Contato"]

export default function HomePage(props: Props) {
  const { window: getWindow } = props

  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [viewLogoTop, setViewLogoTop] = React.useState(false)

  const homeRef = React.useRef()
  const abountRef = React.useRef()
  const serviceRef = React.useRef()
  const portfolioRef = React.useRef()
  const teamRef = React.useRef()
  const contactRef = React.useRef()

  const container =
    getWindow !== undefined ? () => getWindow().document.body : undefined

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const windowScrollTo = (ref: any) => {
    window.scrollTo({
      top: (ref.current?.offsetTop ?? 0) - 50,
      behavior: "smooth",
    })
  }

  const handleHome = () => windowScrollTo(homeRef)

  const handleAbount = () => windowScrollTo(abountRef)

  const handleService = () => windowScrollTo(serviceRef)

  const handlePortfolio = () => windowScrollTo(portfolioRef)

  const handleTeam = () => windowScrollTo(teamRef)

  const handleContact = () => windowScrollTo(contactRef)

  const handleMenuChange = (item: string) => {
    switch (item) {
      case "Sobre":
        handleAbount()
        return
      case "Serviços":
        handleService()
        return
      case "Portfólio":
        handlePortfolio()
        return
      case "Equipe":
        handleTeam()
        return
      case "Contato":
        handleContact()
        return
      default:
        handleHome()
    }
  }

  React.useEffect(() => {
    setViewLogoTop(true)
    return () => setViewLogoTop(false)
  }, [])

  const handleChangeWhatsApp = (): void => {
    const text = `Olá, equipe DevAll Tech!\r\n
Estou entrando em contato porque gostaria de saber mais sobre o desenvolvimento de aplicativos/sites/softwares.\r\n
Aguardo retorno. Obrigado!`
    const url = encodeURI(`https://wa.me/5518997625267?text=${text}`)
    window.open(url, "_blank")
  }

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        <CssBaseline />
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              component="div"
              sx={{ flexGrow: 0, mr: 2, display: { xs: "none", sm: "block" } }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/logo-white.png`}
                alt="logo-white"
                height={42}
              />
            </Box>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "block", sm: "block" },
                textAlign: "start",
              }}
            >
              DevAll Tech
            </Typography>
            <Box
              component="div"
              sx={{
                flexGrow: 1,
                display: { xs: "block", sm: "none" },
                textAlign: "end",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/img/logo-white.png`}
                alt="logo-white"
                height={42}
              />
            </Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button
                  key={item}
                  onClick={() => handleMenuChange(item)}
                  sx={{ color: "#fff" }}
                >
                  {item}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <HomeDrawer
              navItems={navItems}
              handleDrawerToggle={handleDrawerToggle}
              handleMenuChange={handleMenuChange}
            />
          </Drawer>
        </Box>
        <Box component="main" sx={{ p: 0, width: "100%" }}>
          <Toolbar />
          <Box ref={homeRef}>
            <HomeTop
              animation={viewLogoTop}
              handleAbount={handleAbount}
              handleContact={handleContact}
            />
          </Box>

          <Box ref={abountRef}>
            <HomeAbount />
          </Box>
          <HomeIdea />
          <HomeMethodology />

          <Box ref={serviceRef}>
            <HomeService />
          </Box>

          <Box ref={portfolioRef}>
            <HomePortfolio />
          </Box>

          <HomeTestimony />

          <Box ref={teamRef}>
            <HomeTeam />
          </Box>

          <HomeClient />

          <Box ref={contactRef}>
            <HomeContact />
          </Box>

          <HomeFoother />
        </Box>
      </Box>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          backgroundColor: green[500],
        }}
        onClick={handleChangeWhatsApp}
      >
        <WhatsAppIcon />
      </Fab>
    </Box>
  )
}
